/* Import Lucide icons */
@import url('https://cdn.jsdelivr.net/npm/lucide-icons@0.263.1/dist/lucide-icons.min.css');

/* Custom Properties */
:root {
    --glass-bg: rgba(255, 255, 255, 0.1);
    --glass-border: rgba(255, 255, 255, 0.2);
    --glass-shadow: rgba(0, 0, 0, 0.25);
    --accent-color: #64ffda;
    --text-primary: #ffffff;
    --text-secondary: rgba(255, 255, 255, 0.7);
}

/* Base Styles */
body {
    margin: 0;
    padding: 0;
    font-family: 'Inter', sans-serif;
    background: #000;
    color: var(--text-primary);
    min-height: 100vh;
    background-image: url('https://images.unsplash.com/photo-1539721972319-f0e80a00d424');
    background-size: cover;
    background-position: center;
    background-attachment: fixed;
    position: relative;
}

body::before {
    content: '';
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(
        45deg,
        rgba(0, 0, 0, 0.7),
        rgba(0, 0, 0, 0.3)
    );
    z-index: 0;
}

.app {
    position: relative;
    z-index: 1;
}

/* Header & Navigation */
.header {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    background: var(--glass-bg);
    backdrop-filter: blur(10px);
    -webkit-backdrop-filter: blur(10px);
    border-bottom: 1px solid var(--glass-border);
    box-shadow: 0 4px 30px var(--glass-shadow);
    z-index: 1000;
    transition: all 0.3s ease;
    height: 60px; /* Fixed height for navbar */
    display: flex;
    align-items: center;
}

.header-container {
    padding: 0.5rem 0;
    width: 100%;
}

.title {
    font-size: 1.25rem;
    background: linear-gradient(45deg, var(--accent-color), #ffffff);
    -webkit-background-clip: text;
    background-clip: text;
    color: transparent;
    text-shadow: 0 0 10px rgba(100, 255, 218, 0.3);
}

.nav {
    display: flex;
    gap: 1.5rem;
    align-items: center;
    margin: 12px;
    padding: 0;
}

.nav li {
    list-style: none;
}

.nav a {
    color: var(--text-primary);
    text-decoration: none;
    display: flex;
    align-items: center;
    gap: 0.3rem;
    padding: 0.3rem 0.8rem;
    border-radius: 6px;
    transition: all 0.3s ease;
    position: relative;
    font-size: 0.9rem;
}

/* Adjust icon size in navbar */
.nav a svg {
    width: 16px;
    height: 16px;
}

.nav a:hover {
    background: var(--glass-bg);
    transform: translateY(-2px);
}

.nav a::before {
    content: '';
    position: absolute;
    bottom: -2px;
    left: 0;
    width: 0;
    height: 2px;
    background: var(--accent-color);
    transition: width 0.3s ease;
}

.nav a:hover::before {
    width: 100%;
}

/* Main Content Adjustment */
.main {
    margin-top: 80px; /* Adjust margin to account for fixed navbar */
    padding: 0 20px;
}

/* Contact Form Adjustments */
.contact-form {
    max-width: 600px;
    margin: 0 auto;
}

.contact-form input,
.contact-form textarea {
    background: var(--glass-bg);
    border: 1px solid var(--glass-border);
    color: var(--text-primary);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    padding: 12px;
    margin-bottom: 15px;
    width: 100%;
    box-sizing: border-box;
}

.contact-form textarea {
    min-height: 150px;
    resize: vertical;
}

.contact-form label {
    display: block;
    margin-bottom: 5px;
    color: var(--text-primary);
}

.contact-form button {
    background: var(--accent-color);
    color: #000;
    font-weight: bold;
    transition: all 0.3s ease;
    padding: 12px 24px;
    width: 100%;
    cursor: pointer;
}

.contact-form button:hover {
    transform: translateY(-2px);
    box-shadow: 0 5px 15px rgba(100, 255, 218, 0.4);
}

/* Project Cards */
.project-card {
    width: 90%;
    height: 200px;
    display: flex;
    background: var(--glass-bg);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid var(--glass-border);
    border-radius: 12px;
    padding: 1.5rem;
    transition: all 0.3s ease;
    margin-bottom: 1rem;
    background-color: #000;
}

.project-card:hover {
    transform: translateY(-10px);
    box-shadow: 0 10px 30px rgba(100, 255, 218, 0.2);
}

/* Sections */
.section {
    margin: 2rem 0;
    padding: 2rem;
    background: var(--glass-bg);
    backdrop-filter: blur(10px);
    -webkit-backdrop-filter: blur(10px);
    border: 1px solid var(--glass-border);
    border-radius: 16px;
    box-shadow: 0 4px 30px var(--glass-shadow);
    transform-style: preserve-3d;
    transition: transform 0.3s ease;
}

.section:hover {
    transform: translateZ(20px);
}

/* Footer */
.footer {
    background: var(--glass-bg);
    backdrop-filter: blur(10px);
    -webkit-backdrop-filter: blur(10px);
    border-top: 1px solid var(--glass-border);
    padding: 1rem 0;
    margin-top: 2rem;
}

.socials {
    display: flex;
    justify-content: center;
    gap: 1rem;
    margin-bottom: 1rem;
}

.socials a {
    opacity: 0.7;
    transition: all 0.3s ease;
}

.socials a:hover {
    opacity: 1;
    transform: translateY(-3px);
}

.socials img {
    width: 24px;
    height: 24px;
}

/* Animations */
@keyframes float {
    0% {
        transform: translateY(0px);
    }
    50% {
        transform: translateY(-10px);
    }
    100% {
        transform: translateY(0px);
    }
}

.title {
    animation: float 3s ease-in-out infinite;
}

/* Responsive Design */
@media (max-width: 768px) {
    .header {
        height: auto;
    }
    
    .header-container {
        flex-direction: column;
        gap: 0.5rem;
        padding: 1rem 0;
    }
    
    .nav {
        flex-direction: column;
        gap: 0.5rem;
    }
    
    .main {
        margin-top: 120px;
    }
    
    .contact-form {
        padding: 0 1rem;
    }
}